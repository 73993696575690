// const data = [
//   {
//     title: "Symbiosis Day 3",
//     brand: "studio",
//     images: ["assets/images/studio/03-symbiosis-daythree-01.jpg", "assets/images/studio/05-symbiosis-dayfive-01.jpg"],
//   },
//   {
//     title: "Symbiosis Day 5",
//     brand: "studio",
//     images: ["assets/images/studio/05-symbiosis-dayfive-01.jpg"],
//   },
//   {
//     title: "Symbiosis Day 6",
//     brand: "studio",
//     images: ["assets/images/studio/06-symbiosis-daysix-01.jpg"],
//   },
//   {
//     title: "Symbiosis Day 10",
//     brand: "studio",
//     images: ["assets/images/studio/10-symbiosis-dayten-01.jpg"],
//   },
//   {
//     title: "Symbiosis Day 14",
//     brand: "studio",
//     images: ["assets/images/studio/14-symbiosis-dayfourteen-01.jpg"],
//   },
//   {
//     title: "Symbiosis Day 10",
//     brand: "studio",
//     illustration: true,
//     images: ["assets/images/studio/10-symbiosis-dayten-01.jpg"],
//   },
//   {
//     title: "Symbiosis Day 14",
//     brand: "studio",
//     illustration: true,
//     images: ["assets/images/studio/14-symbiosis-dayfourteen-01.jpg"],
//   },


//   {
//     title: "Josephine",
//     brand: "collagecvlt",
//     // client: "No two",
//     images: ["assets/images/collagecvlt/collagecvlt-insta-josephine-dark.jpg"],
//   },
//   {
//     title: "Beetle Blossom",
//     brand: "collagecvlt",
//     // client: "No two",
//     images: ["assets/images/collagecvlt/collagecvlt-insta-landscape-beetleblossom.jpg"],
//   },
// ]

// const data = [{ "title": "tarot", "previewImage": "assets\\images\\collagecvlt\\01 tarot\\preview.jpg", "images": ["assets\\images\\collagecvlt\\01 tarot\\image (1).jpg", "assets\\images\\collagecvlt\\01 tarot\\image (2).jpg", "assets\\images\\collagecvlt\\01 tarot\\image (3).jpg"], "brand": "collagecvlt", "quote": "yo", "tags": ["I", "am", "a", "tag"], "client": "bob", "illustration": false }, { "title": "tarot", "previewImage": "assets\\images\\shop\\01 tarot\\preview.jpg", "images": ["assets\\images\\shop\\01 tarot\\image (1).jpg", "assets\\images\\shop\\01 tarot\\image (2).jpg", "assets\\images\\shop\\01 tarot\\image (3).jpg"], "brand": "shop", "quote": "yo", "tags": ["I", "am", "a", "tag"], "client": "bob", "illustration": false }, { "title": "tarot", "previewImage": "assets\\images\\studio\\collage 01 tarot\\preview.jpg", "images": ["assets\\images\\studio\\collage 01 tarot\\image (1).jpg", "assets\\images\\studio\\collage 01 tarot\\image (2).jpg", "assets\\images\\studio\\collage 01 tarot\\image (3).jpg"], "brand": "studio", "quote": "yo", "tags": ["I", "am", "a", "tag"], "client": "bob", "illustration": false }, { "title": "tarot", "previewImage": "assets\\images\\studio\\illustration 01\\preview.jpg", "images": ["assets\\images\\studio\\illustration 01\\image (1).jpg", "assets\\images\\studio\\illustration 01\\image (2).jpg", "assets\\images\\studio\\illustration 01\\image (3).jpg"], "brand": "studio", "quote": "yo", "tags": ["I", "am", "a", "tag"], "client": "bob", "illustration": true }]
import dataEntries from "../scripts/data-entries";
import playlists from "../scripts/data-playlists";
import content from "../scripts/data-content";

export const entries = dataEntries.map((e, i) => {
  // let previewImage = e.previewImage;
  // if (previewImage) {
  //   const img = new Image();
  //   img.src = previewImage;
  //   previewImage = img;
  // }

  return {
    ...e,
    id: i.toString(),
    // previewImage,
    // images: (e.images && e.images.map(src => {
    //   const img = new Image();
    //   img.src = src;
    //   return img;
    // })) ?? [],
    tags: e.tags ?? [],
  }
});

export const tags = entries.reduce((tags, e) => ([
  ...tags,
  ...e.tags.filter(t => !tags.includes(t))
]), []).sort();

export const brands = entries.reduce((brands, e) => ([
  ...brands,
  ...(brands.includes(e.brand) ? [] : [e.brand])
]), []);

let brandTags = {};
brands.forEach(brand => {
  brandTags[brand] = entries.filter(e => e.brand === brand).reduce((tags, e) => ([
    ...tags,
    ...e.tags.filter(t => !tags.includes(t))
  ]), []).sort();
});

export { brandTags };

export { playlists };

export { content };